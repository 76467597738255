<template>
  <div class="vh-100 w-100">
    <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>
    <div class="d-flex justify-content-center align-items-center h-100">
        <div class="container">
            <div class="row justify-content-center">
            <div class="col-md-5">
                <div class="card-group mb-0">
                <div class="card p-4">
                    <div class="card-body">
                    <h1>
                        <i class="mdi mdi-lock-reset"></i>
                    </h1>
                    <p class="text-muted">
                        Enter your email address and we will send you an email with instructions to reset your password.
                    </p>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="mdi mdi-email-outline"></i>
                        </span>
                        </div>
                        <input type="email" class="form-control" placeholder="Email" v-model="email">
                    </div>
                    <div class="row">
                        <div class="col-6">
                        <button class="btn btn-primary px-4" @click="sendEmail">
                            Send Email
                        </button>
                        </div>
                        <div class="col-6 text-center">
                        <router-link to="/login" class="btn btn-link px-0">
                            Login
                        </router-link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            email: '',
        }
    },
    methods: {
        sendEmail() {
            if (this.email) {
                console.log("Email bhejega")
            }
        }
    }
};
</script>
<style lang="scss" scoped></style>
